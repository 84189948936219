import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const SuspensionPlayerPage = () => {
  return (
    <Layout>
      <SEO title="Rankings" />
      <Router basepath="/suspension">
        <PlayerRoute path="/:playerId" />
      </Router>
    </Layout>
  );
};

interface PlayerRouteProps extends RouteComponentProps {
  playerId?: string;
}

const PlayerRoute: React.FC<PlayerRouteProps> = ({ playerId }) => {
  return (
    <PrivateRoute>
      <div>{playerId}</div>
    </PrivateRoute>
  );
};

export default SuspensionPlayerPage;
